import { downloadAndResizeImage } from "../../utils/image";
import { uploadToS3 } from "../clientSideServices/session";

export const assetFeatureName = 'feature.jpeg';

export const setImageAsFeaturedImage = async (imageUrl: string, brand:string, id: string) => {
    const filePath = 'assets/converted';
    const bucket = 'storiez-campaignpics';
    const key = `${filePath}/${brand}/${id}/featuredImages/${assetFeatureName}`;
    try {
      const image = await downloadAndResizeImage(imageUrl, 1200, 627);
      await uploadToS3(image, bucket, key, undefined, true, 'image/jpeg');
      return key;
    } catch (e) {
      console.log(e);
      return '';
    }
  };
  
export const getFeaturedImageUrl = (brand:string, id: string) => {
    return `https://stories.inspify.io/assets/converted/${brand}/${id}/featuredImages/feature.jpeg`
}